import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageUrlPipe } from 'src/app/pipes/image-url.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { DaysHoursPipe } from './dayshours.pipe';


@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        ImageUrlPipe,
        LocalizedDatePipe,
        DaysHoursPipe
    ],
    declarations: [
        ImageUrlPipe,
        LocalizedDatePipe,
        DaysHoursPipe
    ]
})
export class PipesModule { }
