import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input() color: string;

  ngOnInit(): void {
    if(!this.color) {
      this.color = '#fff';
    }
  }

}
